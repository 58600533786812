import React from "react";

import { css } from "@emotion/core";

export interface ButtonProps {}

const Button: React.FC<ButtonProps> = props => {
  return (
    <button
      css={css`
        position: relative;
        background-color: transparent;
        border: 2px solid black;
        padding: 5px 13px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        font-weight: 300;
        letter-spacing: 0.1em;
        transition: color 0.24s ease-in-out;
        font-size: 1rem;
        z-index: 2;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: black;
          width: 0;
          transition: width 0.3s ease;
          z-index: -1;
        }

        &:hover {
          color: white;

          &:after {
            width: 100%;
          }
        }
      `}
    >
      {props.children}
    </button>
  );
};

export default Button;
