import React from "react";
import SEO from "../components/seo";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Button from "../components/button";
import Layout from "../components/layout";
import SectionCard from "../components/section-card";
import ContentCard from "../components/content-card";
import { Parallax } from "react-parallax";
import { Link, graphql } from "gatsby";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const CUBIT_CUSTOMERS = [
  "gana",
  "disenat",
  "dermalife",
  "kipi",
  "centro-aceros",
  "proyectat",
  "tinggo",
  "ayr",
  "madre-monte",
];

const Hero = styled.div`
  height: 100vh;
  position: relative;
`;

export interface IndexPageProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            author: string;
            description: string;
            slug: string;
          };
        };
      }[];
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  const lastBlogs = data.allMarkdownRemark.edges.map(
    edge => edge.node.frontmatter
  );

  return (
    <Layout>
      <SEO />
      <Hero className="columns mx-0 my-0">
        <div
          className="column"
          css={{
            position: "relative",
            zIndex: 2,
            height: "100%",
          }}
        >
          <div
            className="px-6"
            css={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
              fontSize: "1.1em",
            }}
          >
            <Fade left>
              <div>
                <h1
                  className="text-black text-secondary"
                  css={css`
                    font-size: 2rem;

                    @media (min-width: 768px) {
                      font-size: 3rem;
                    }
                  `}
                >
                  <span className="text-thin text-primary">Materializamos</span>
                  <br />
                  tus ideas.
                </h1>
                <p
                  css={{ fontSize: "1.1em", fontWeight: 100 }}
                  className="has-text-weight-light text-primary text-left"
                >
                  Somos los innovadores de las marcas listas para transformarse.
                </p>
                <Link to="/projects">
                  <Button>CONOCE MÁS {">"}</Button>
                </Link>
              </div>
            </Fade>
          </div>
        </div>
        <div
          className="column is-hidden-mobile px-0 py-0"
          css={css`
            height: 100%;
            width: 100%;
            border-radius: 0 0 0 3em;
            overflow: hidden;
          `}
        >
          <Parallax
            bgImageStyle={{ objectFit: "cover" }}
            bgImage="/images/hands_hero_image.jpg"
            strength={400}
          >
            <div css={{ height: "100vh" }}></div>
          </Parallax>
        </div>
      </Hero>

      <div className="container">
        <SectionCard>
          <Fade right>
            <h2 className="title">
              <span className="text-thin text-primary">Innovación</span>
              <br />a tu alcance
            </h2>
          </Fade>

          <Fade bottom cascade>
            <div className="columns i-3">
              <div className="column">
                <ContentCard
                  className="h-100"
                  title="Juntos de la mano"
                  backgroundImage="/images/light_bulb_hand.png"
                >
                  Buscamos aliados estratégicos para desarrollar proyectos 
                  basados en innovación, estructurando estrategias que 
                  generen valor en el mercado.
                </ContentCard>
              </div>

              <div className="column">
                <ContentCard
                  className="h-100"
                  title="Creando soluciones"
                  backgroundImage="/images/developer_enterprise.png"
                >
                  No tendrás que preocuparte por cómo solucionar o mejorar
                  los procesos de tu empresa, en Cubit diseñamos modelos
                  innovadores, desarrollados con la mejor tecnología.
                </ContentCard>
              </div>

              <div className="column">
                <ContentCard
                  className="h-100"
                  title="Consultoría en desarrollo"
                  backgroundImage="/images/dermalife_phone.png"
                >
                  Te asesoramos en el uso de tecnologías, buscando productos
                  escalables que cuenten con una excelente arquitectura y
                  buenas prácticas de desarrollo.
                </ContentCard>
              </div>
            </div>
          </Fade>
        </SectionCard>
        <SectionCard>
          <div className="columns">
            <div className="column is-half">
              <Fade left>
                <h2 className="title">
                  <span className="text-thin text-primary">Aprendamos</span>
                  <br />
                  Juntos.
                </h2>

                <p>
                  En Cubit nos encanta aprender y construir, por esto queremos
                  que camines junto a nosotros y descubramos nuevas maneras de
                  innovar.
                </p>
                <p>Ingresa a nuestro blog y mira en que estamos trabajando</p>
                <Link to="/blogs">
                  <Button>Conoce más {">"}</Button>
                </Link>
              </Fade>
            </div>

            <div className="column is-half">
              <Fade right>
                <div>
                  {lastBlogs.map(blog => (
                    <Link
                      key={blog.slug}
                      className="flat-link"
                      to={`/blog/${blog.slug}`}
                    >
                      <ContentCard
                        title={blog.title}
                        className="mb-4"
                        backgroundImage={`/headers/${blog.slug}.jpg`}
                        short
                      >
                        <p className="text-white mb-0 text-black">
                          {blog.author}
                        </p>
                        <p className="text-white">
                          {blog.description.length > 70
                            ? `${blog.description.substr(0, 70).trim()}...`
                            : blog.description}
                        </p>
                      </ContentCard>
                    </Link>
                  ))}
                </div>
              </Fade>
            </div>
          </div>
        </SectionCard>

        <div className="px-6 my-6 py-6">
          <h2 className="title">
            <span className="text-thin text-primary">Nuestros</span>
            <br />
            Clientes
          </h2>

          <Flip bottom>
            <div
              css={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {CUBIT_CUSTOMERS.map((customer: string) => (
                <div
                  key={customer}
                  css={{
                    flex: 1,
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    css={{
                      width: "90%",
                      height: "80px",
                      backgroundPosition: "center center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      marginBottom: "2em",
                    }}
                    style={{
                      backgroundImage: `url(/images/customers/${customer}-icon.png)`,
                    }}
                  ></div>
                </div>
              ))}
            </div>
          </Flip>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            author
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
