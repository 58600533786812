import styled from "@emotion/styled";

const SectionCard = styled.section({
  borderRadius: "2em",
  backgroundColor: "#fff",
  padding: "2em",
  margin: "8em 1em 0 1em",
  boxShadow: "0 10px 20px -5px rgba(0, 0, 0, 0.4)",
  "@media (min-width: 768px)": {
    padding: "2em 5em",
  },
  position: "relative",
});

export default SectionCard;
