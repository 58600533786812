import React from "react";
import { css } from "@emotion/core";

export interface ContentCardProps {
  title: string;
  backgroundImage: string;
  className?: string;
  short?: boolean;
  obscuredPrimary?: boolean;
}

const ContentCard: React.FC<ContentCardProps> = props => {
  return (
    <div
      className={props.className}
      css={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        fontSize: "16px",
        borderRadius: "2em",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1.2em",
        paddingTop: props.short ? "60px" : "180px",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        ":after": {
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#0A286A",
          opacity: 0.5,
          display: "block",
          zIndex: 1,
        },
        "@media (min-width: 768px)": {
          transition: "transform .4s ease",
          ":hover": {
            transform: "scale(1.06)",
          },
        },
        "h2, p": {
          position: "relative",
          zIndex: 10,
        },
      }}
    >
      <h2 className="text-accent mb-2">
        <span className="text-thin ">{props.title.split(' ')[0]}</span>
        <br />{props.title.slice(props.title.split(' ')[0].length)}
      </h2>
      {typeof props.children !== "string" ? (
        <div>{props.children}</div>
      ) : (
        <p className="mb-0 text-white" css={{textAlign: "left"}}>{props.children}</p>
      )}
    </div>
  );
};

ContentCard.defaultProps = {
  short: false,
  obscuredPrimary: false,
};

export default ContentCard;
